import React from "react"
import { graphql } from "gatsby"
import './template.scss'

import Navbar from '../../components/Navbar/Navbar.tsx'
import Footer from '../../components/Footer/Footer.tsx'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <Navbar />
      <div className="project-container">
        <div className="header project-padding">
          <h1 className="page-heading">
            <span>{frontmatter.title}</span>
            <span className="dot" style={{ color: '#FFCBE5'}}>.</span>
          </h1>
        </div>
        <div className="hero-image">
          <img src={frontmatter.hero.publicURL} alt="" />
        </div>
        <div className="project-post project-padding">
          <div
            className="project-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        hero {
          publicURL
        }
      }
    }
  }
`